import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function whoweare() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-1 text-white animated slideInDown">
            Aerospace
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
             
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Aerospace
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="">
                <img className="img-fluid" src="img/industry1.jpeg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h4 className="section-title">Aerospace Industry</h4>
              <p>
                Our company offers comprehensive door-to-door supply chain
                solutions, encompassing ocean freight, air freight, and
                warehousing services, specifically tailored to meet the unique
                demands of the aerospace industry. In addition, our global
                customs compliance consulting service ensures smooth customs
                processes for your shipments.
                <br />
                We understand the importance of time sensitivity in aerospace
                logistics, and our team operates with a sense of urgency to
                handle your hand-carry requirements promptly and efficiently.
              </p>
            </div>
          </div>
          <p><br/>
            With our in-depth knowledge of the aerospace industry and our
            ability to adapt to your evolving needs, our dedicated aerospace
            logistics team is ready to support your supply chain requirements.
            Whether you require shipping, transportation, or specialized advice,
            {companyname} is here to provide expert guidance and
            ensure that your freight reaches its destination seamlessly.
          </p>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
