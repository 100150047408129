import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
     
      
        
        {/* Navbar Start */}
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
          <Link to="/Home" className="navbar-brand ms-4 ms-lg-0">
            <img className="me-3" src="img/logo.png"  alt="Icon" style={{height:'120px'}} />
          </Link>
          <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
              <Link to="/Home" className="nav-item nav-link active">Home</Link>
              <Link to="/About" className="nav-item nav-link">Company Profile</Link>
              
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">What We Offer</Link>
                <div className="dropdown-menu border-0 m-0">
                  <Link to="/Sea" className="dropdown-item">Sea Freight</Link>
                  <Link to="/Air" className="dropdown-item">Air Freight</Link>
                  <Link to="/Ground" className="dropdown-item">Ground Transportation</Link>
                  <Link to="/Warehousing" className="dropdown-item">Warehousing & Distribution</Link>
                  <Link to="/Projects" className="dropdown-item">Projects & Break Bulk</Link>
                  <Link to="/Hazardous" className="dropdown-item">Hazardous Cargo</Link>

                  
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Industry Solutions</Link>
                <div className="dropdown-menu border-0 m-0">
                  <Link to="/Aerospace" className="dropdown-item">Aerospace</Link>
                  <Link to="/Automotive" className="dropdown-item">Automotive</Link>
                  <Link to="/Consumer" className="dropdown-item">Consumer Goods</Link>
                  <Link to="/Energy" className="dropdown-item">Energy</Link>
                  <Link to="/Life" className="dropdown-item">Life Science</Link>
                  <Link to="/Mining" className="dropdown-item">Mining</Link>

                  
                </div>
              </div>

            </div>
            <Link to="/Contact" className="btn btn-primary py-2 px-4 d-none d-lg-block">Contact Us</Link>
          </div>
        </nav>
        {/* Navbar End */}
      

      <Outlet />
    </>
  );
};

export default Header;
