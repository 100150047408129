import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Energy() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-1 text-white animated slideInDown">
          Energy
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Energy
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="">
                <img className="img-fluid" src="img/industry2.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h4 className="section-title">Energy Industry</h4>
              <p>
              The oil and gas industry is experiencing rapid evolution, which necessitates the need for oil and gas logistics companies to provide innovative and efficient solutions. Despite an anticipated decline of 7.9%, the market size of the oil drilling and gas extraction industry is projected to be approximately $737.3 billion in 2023, as reported by IBISWorld. In the face of uncertainty, energy companies are seeking ways to enhance their operational capabilities and improve efficiency. This pursuit begins with establishing effective partnerships.<br />

By forging strong partnerships with oil and gas logistics companies, energy companies can access the expertise and resources necessary to navigate the evolving industry landscape. These collaborations enable them to develop tailored solutions that address specific operational challenges, optimize logistics processes, and enhance overall efficiency.<br />


                <br />
              </p>
            </div>
          </div>
          <p>
            <br />
            The key to success lies in selecting logistics partners that possess a deep understanding of the oil and gas industry, along with a track record of delivering innovative solutions. Such partnerships facilitate the implementation of advanced technologies, streamlined supply chain management, and cost-effective transportation solutions. By leveraging these capabilities, energy companies can adapt to the evolving market dynamics, optimize their operations, and achieve their strategic goals.<br />

In summary, effective partnerships with oil and gas logistics companies are crucial for energy companies to thrive in an evolving industry. These collaborations provide access to innovative solutions, enhanced operability, and improved efficiency, enabling energy companies to overcome challenges and drive success in the dynamic oil and gas sector.<br />
          </p>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
