import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Projects() {
  return (
    <>
    <Header />

    {/* Page Header Start */}
    <div
      className="container-fluid page-header py-5 mb-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <h1 className="display-1 text-white animated slideInDown">
        What We Offer
        </h1>
        <nav aria-label="breadcrumb animated slideInDown">
          <ol className="breadcrumb text-uppercase mb-0">
            <li className="breadcrumb-item">
              <a className="text-white" href="#">
                Home
              </a>
            </li>

            <li
              className="breadcrumb-item text-primary active"
              aria-current="page"
            >
              What We Offer
            </li>
          </ol>
        </nav>
      </div>
    </div>
    {/* Page Header End */}
    {/* Project Start */}
    <div className="container-xxl project py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <h4 className="section-title">Services We Offer</h4>
          <h1 className="display-5 mb-4">
            
          </h1>
        </div>
        <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-3">
            <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-2 mb-2 "
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-1"
                type="button"
              >
                <h4 className="m-0">Sea Freight</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-2 mb-2 "
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-2"
                type="button"
              >
                <h4 className="m-0">Air Freight</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-2 mb-2 "
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-3"
                type="button"
              >
                <h4 className="m-0">Ground Transportation</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-2 mb-2 "
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-4"
                type="button"
              >
                <h4 className="m-0">Warehousing & Distribution</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-2 mb-2 active"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-5"
                type="button"
              >
                <h4 className="m-0">Projects & Break Bulk</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-2 mb-2 "
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-6"
                type="button"
              >
                <h4 className="m-0">Hazardous Cargo</h4>
              </button>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="tab-content w-100">
              <div className="tab-pane fade show " id="tab-pane-1">
                <div className="row g-4">
                  <div className="col-md-6" style={{ minHeight: "350px" }}>
                    <div className="position-relative h-100">
                      <img
                        className="position-absolute img-fluid w-100 h-100"
                        src="img/service-1.jpg"
                        style={{ objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h1 className="mb-3">Sea Freight</h1>
                    <p className="mb-4">
                      We serve as the gateway to your sales and procurement
                      market, providing innovative solutions that connect your
                      shipments to their destinations. With contracts in place
                      with multiple carriers, we serve as a single-source
                      logistics provider for all your products throughout the
                      supply chain.
                      <br />
                      Our comprehensive range of services is designed to meet
                      your specific needs. We offer flexible and reliable
                      sailing schedules, along with shipment tracking,
                      purchase order management, and space protection during
                      peak demand periods. Navigating the complexities of
                      international shipping is made easier with our tailored
                      and all-encompassing solutions.
                    </p>
                  </div>
                  <p>
                    <br />
                    Our customized services include worldwide consolidation of
                    cargo, both full container load (FCL) and less than
                    container load (LCL) consolidation, as well as multimodal
                    transportation options via sea, air, and road. We also
                    offer custom clearance and brokerage services, cargo
                    insurance services, and a full range of pick-up, packing,
                    delivery, and distribution options. Our advanced cargo
                    tracking system ensures transparency and peace of mind,
                    while our dedicated team handles all documentation
                    requirements.
                    <br />
                    With our comprehensive and customized services, we are
                    committed to delivering seamless logistics solutions that
                    support your global trade operations.
                  </p>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-pane-2">
                <div className="row g-4">
                  <div className="col-md-6" style={{ minHeight: "350px" }}>
                    <div className="position-relative h-100">
                      <img
                        className="position-absolute img-fluid w-100 h-100"
                        src="img/service-2.jpg"
                        style={{ objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h1 className="mb-3">Air Freight</h1>
                    <p className="mb-4">
                      Our specialization in air freight solutions, coupled
                      with our team of dedicated account managers and
                      round-the-clock service assurance, sets us apart and
                      creates exceptional synergies for your business
                      prosperity. <br />
                      <br />
                      Here are some of the key features we offer:
                      <br />
                      <br />
                      1. Worldwide Consolidation Services: We provide
                      efficient consolidation services on a global scale,
                      enabling streamlined and cost-effective transportation
                      of your goods.
                      <br />
                      2. Cargo Pick-up: Our team arranges for the pick-up of
                      cargo from the consignee's warehouse, ensuring a
                      seamless flow throughout the logistics process.
                      <br />
                    </p>
                  </div>
                  <p>
                    3. Expediting and Vendor Management: We expedite the
                    movement of your shipments and effectively manage vendors
                    to ensure timely delivery and minimize any potential
                    disruptions.
                    <br />
                    4. Export and Import Clearance: We handle all necessary
                    export clearance procedures at the country of origin and
                    import clearance at the port of discharge, ensuring smooth
                    customs processes.
                    <br />
                    5. In-house Export Packing: We offer professional and
                    reliable export packing services to safeguard your goods
                    during transit, providing added peace of mind.
                    <br />
                    6. Complete Documentation Service: Our experienced team
                    takes care of all documentation requirements, ensuring
                    compliance and smooth documentation processes.
                    <br />
                    7. Track and Trace System: Our advanced track and trace
                    system allows you to monitor the progress of your
                    shipments in real-time, enhancing visibility and
                    transparency.
                    <br />
                  </p>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-pane-3">
                <div className="row g-4">
                  <div className="col-md-6" style={{ minHeight: "350px" }}>
                    <div className="position-relative h-100">
                      <img
                        className="position-absolute img-fluid w-100 h-100"
                        src="img/service-3.jpg"
                        style={{ objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h1 className="mb-3">Ground Transportation</h1>
                    <p className="mb-4">
                      Our land transport services are designed to meet the
                      specific requirements of our customers, ensuring precise
                      alignment with their needs. We provide clear and defined
                      transit times, offering you the peace of mind to plan
                      your budget reliably and monitor the seamless transition
                      of your goods.
                      <br />{" "}
                    </p>
                  </div>
                  <p>
                    With our dedicated fleet of containers and trailers, we
                    prioritize the total safety of transporting your goods,
                    adhering to the principles of care and perfection. Our
                    fast and reliable services guarantee the delivery of your
                    goods in absolutely flawless condition.
                    <br /> <br />
                    As a customer-oriented service provider, we take great
                    pride in our commitment to securely transport your cargo.
                    It is our utmost goal to ensure the safety and
                    satisfaction of our customers throughout the
                    transportation process.
                  </p>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-pane-4">
                <div className="row g-4">
                  <div className="col-md-6" style={{ minHeight: "350px" }}>
                    <div className="position-relative h-100">
                      <img
                        className="position-absolute img-fluid w-100 h-100"
                        src="img/service-4.jpg"
                        style={{ objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h1 className="mb-3">Warehousing & Distribution</h1>
                    <p className="mb-4">
                      Our company offers comprehensive warehousing network
                      solutions, supported by well-trained manpower and
                      excellent managerial services. We prioritize
                      transparency in our processes and provide agile
                      services, underpinned by a robust infrastructure that
                      drives your business with a scalable supply chain. By
                      partnering with us for contract logistics, you can
                      benefit from: <br />
                      <br />
                      1. End-to-end Surveillance: We ensure the security of
                      your goods with comprehensive surveillance systems
                      throughout the warehousing process.
                      <br />
                      2. Material Handling: Our expertise extends to handling
                      various types of cargo, including standard and
                      non-standard shipments, oversized shipments (ODC),
                      perishable goods, and volatile goods.
                      <br />
                    </p>
                  </div>
                  <p>
                    3. Asset Light Model: Our efficient operations follow an
                    asset-light model, enabling us to optimize resources and
                    deliver cost-effective solutions.
                    <br />
                    4. Customized Storage Solutions: We provide tailored
                    storage solutions based on your specific requirements,
                    ensuring optimal utilization of space and efficient
                    organization of your inventory.
                    <br />
                    5. Flexible Space and Transportation: Our warehousing
                    facilities offer flexible space options to accommodate
                    your changing needs. Additionally, we provide
                    transportation services to seamlessly move your goods
                    within the supply chain.
                    <br />
                  </p>
                </div>
              </div>
              <div className="tab-pane fade show active" id="tab-pane-5">
                <div className="row g-4">
                  <div className="col-md-6" style={{ minHeight: "350px" }}>
                    <div className="position-relative h-100">
                      <img
                        className="position-absolute img-fluid w-100 h-100"
                        src="img/service-5.jpg"
                        style={{ objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h1 className="mb-3">Projects & Break Bulk</h1>
                    <p className="mb-4">
                    Our expertise lies in handling project cargo that requires special care, attention, and expertise. We serve a wide range of industries, including oil, gas, construction, energy, maritime, mining, and heavy machinery. Our services cover engines for maritime and shoreside use, as well as terminal equipment. We have successfully executed tasks with customized solutions even in challenging project sites that are difficult to access, demonstrating our trustworthy approach to get the job done.

                    <br />When it comes to complex transport logistics, whether it involves Break Bulk (BB), Heavy Lift (HL), or Out of Gauge (OOG) cargo, we have the capability to handle it all. By choosing {companyname} Logistics as your preferred partner, you can rely on us to manage and execute your projects from inception to completion.

                    </p>
                  </div>
                  <p>
                  <br />Expand your business vision and pursue ambitious projects with confidence, knowing that our unwavering commitment will turn your projects into reality. With our extensive experience and dedication, we are here to support and transform your projects into successful ventures.
                    
                    <br />
                  </p>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-pane-6">
                <div className="row g-4">
                  <div className="col-md-6" style={{ minHeight: "350px" }}>
                    <div className="position-relative h-100">
                      <img
                        className="position-absolute img-fluid w-100 h-100"
                        src="img/service-6.jpg"
                        style={{ objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h1 className="mb-3">Hazardous Cargo</h1>
                    <p className="mb-4">
                    {companyname} specializes in providing comprehensive logistics solutions for dangerous and hazardous cargo. Our business is built on a foundation of expert knowledge and technical expertise. We prioritize human and environmental safety, ensuring strict compliance with rules and regulations.

                    <br /><br />Our dedicated team of professionals is committed to upholding global safety standards. We handle the handling, loading, and dispatch of dangerous and hazardous cargo with utmost care and efficiency.

                    <br />
                      
                    </p>
                  </div>
                  <p>
                  <br />We are well-prepared to handle a wide range of hazardous cargoes, including flammable liquids, flammable solids, toxic substances, corrosives, and miscellaneous dangerous goods. Our expertise extends to various classes of hazardous cargo, with a particular specialization in class 5.1 and 4.1.

<br /><br />We strictly adhere to the regulations set by international organizations such as ICAO (International Civil Aviation Organization), IATA (International Air Transport Association), and IMDG (International Maritime Dangerous Goods) for the transportation and safe handling of all types of dangerous goods.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Project End */}
    <Footer />
  </>
  );
}
