import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Mining() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-1 text-white animated slideInDown">
          Mining
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Mining
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="">
                <img className="img-fluid" src="img/industry2.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h4 className="section-title">Mining Industry</h4>
              <p>
                {companyname} is dedicated to providing comprehensive global
                transportation solutions and integrated logistics services to
                the mining industry. We understand the unique challenges faced
                by mining companies and offer a range of tailored solutions to
                meet their specific needs.
                <br />
                Our logistics services encompass end-to-end solutions, including
                project management, transportation, and supply chain
                optimization. With real-time fleet tracking and supply chain
                mapping capabilities, we provide our clients with full
                visibility and control over their operations. This enables
                better management of the business, ensuring efficient and
                effective mine site operations.
                <br />
              </p>
            </div>
          </div>
          <p>
            <br />
            We believe that the best way to serve our clients is by equipping
            them with the necessary capabilities, tools, and resources to
            effectively manage their mining business. Our experienced team is
            committed to supporting our clients every step of the way, allowing
            them to focus on what matters most – their core business. With our
            expertise and industry-specific solutions, we aim to ensure that
            your mine sites operate seamlessly and efficiently.
            <br />
            At {companyname}, we are dedicated to serving the mining industry
            and helping our clients overcome logistics challenges with
            innovative solutions. Trust us to be your reliable partner,
            providing the necessary support to drive your mining operations
            forward.
            <br />
          </p>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
