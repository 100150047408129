import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Consumer() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-1 text-white animated slideInDown">
            Consumer Goods
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Consumer Goods
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="">
                <img className="img-fluid" src="img/industry3.jpeg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h4 className="section-title"> Consumer Goods Industry</h4>
              <p>
                Utilizing a customs bonded warehouse offers several benefits in
                the logistics process. Goods can be securely held within the
                bonded facility until duties and inspections are addressed,
                providing peace of mind and compliance with customs regulations.{" "}
                <br />
                One advantage is the ability for exporters to defer the payment
                of duties and taxes until the goods have been purchased, helping
                to improve cash flow. This flexibility can be particularly
                advantageous in certain scenarios.
                <br />
                Bonded storage also allows for significant cost savings by
                avoiding immediate duty payments and reducing the need for
                additional warehousing or storage facilities. Furthermore,
                storing goods in bonded warehouses located closer to the end
                user can enhance supply chain efficiency and reduce
                transportation costs.
                <br />
              </p>
            </div>
          </div>
          <p>
            <br />
            Our global network of bonded facilities, combined with the expertise
            of our consumer goods logistics team, enables us to offer agile and
            flexible solutions to support your operations. We understand the
            unique requirements of consumer goods logistics and are committed to
            providing customized services that meet your specific needs.
            <br />
            By leveraging bonded facilities and our logistics expertise, we can
            help optimize your supply chain, streamline customs procedures, and
            enhance cost efficiency. Contact us to explore how we can support
            your operations and provide tailored solutions for your consumer
            goods logistics requirements.
            <br />
            <br />
          </p>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
