import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <div className="container-fluid p-0 pb-5 w3-banner jarallax" data-wow-delay="0.1s" style={{background :'#0f090985', height: '550px' }}>
      <video className="img-fluid" autoPlay muted loop style={{ zIndex: -1 }}>
          <source src="img/video.mp4" type="video/mp4" />
        </video>
        <div
          className="owl-carousel header-carousel position-relative"
          style={{ display: "-webkit-inline-box",}}
        >
          <div
            className="owl-carousel-item position-relative " 
            
          >
           
            <div className="owl-carousel-inner">
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-10 col-lg-8 ">
                    <h1 className="display-1 text-white animated slideInDown">
                      Best Freight And logistics Services
                    </h1>
                    <p className="fs-5 fw-medium text-white mb-4 pb-3">
                    Our dedicated team of professionals is committed to delivering personalized solutions tailored to meet the unique needs of our clients.
                    </p>
                    <Link
                      to="/About"
                      className="btn btn-primary py-3 px-5 animated slideInLeft"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      {/* Facts Start */}
      <div className="container-xxl py-5">
        <div className="container pt-5">
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img
                    src="img/icons/vision.png"
                    alt="Icon"
                    style={{ width: "80px" }}
                  />
                </div>
                <h3 className="mb-3">Our Vision</h3>
                <p className="mb-0">
                  Our goal is to continuously cultivate long-lasting concepts by
                  delivering efficient logistics services through extensive
                  analysis of our target customers. With a profound
                  understanding of contemporary technology, we strive to
                  seamlessly revolutionize our clients' businesses across
                  borders.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img
                    src="img/icons/mission.png"
                    alt="Icon"
                    style={{ width: "80px" }}
                  />
                </div>
                <h3 className="mb-3">Mission</h3>
                <p className="mb-0">
                  We aim to achieve profitable and consistent growth by offering
                  superior world-class transportation and logistics services.
                  Our commitment lies in delivering innovation, quality, and
                  total dedication to our clients. We firmly believe in
                  providing end-to-end transportation and logistics solutions in
                  a sustainable manner.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img
                    src="img/icons/benefits.png"
                    alt="Icon"
                    style={{ width: "80px" }}
                  />
                </div>
                <h3 className="mb-3">Our Value</h3>
                <p className="mb-0">
                our core value is to provide reliable and efficient logistics solutions to our clients. We prioritize open communication, ensuring that our clients are well-informed about the progress of their shipments and can trust us to deliver on time. We are committed to delivering high-quality service by maintaining well-trained staff, streamlined processes, and modern equipment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Facts End */}
       {/* Service Start */}
       <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h4 className="section-title">Our Services</h4>
            <h1 className="display-5 mb-4">
              We Focused On Modern Architecture And Interior Design
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-1.jpg" alt="" />
                <div className="service-text p-5">
                  <img
                    className="mb-4"
                    src="img/icons/icon1.png"
                    alt="Icon"
                    style={{ width: "100px" }}
                  />
                  <h3 className="mb-3">Sea Freight</h3>
                  <p className="mb-4">
                  We serve as the gateway to your sales and procurement market, providing innovative solutions that connect your shipments to their destinations.
                  </p>
                  <Link className="btn" to="/Sea">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-2.jpg" alt="" />
                <div className="service-text p-5">
                  <img
                    className="mb-4"
                    src="img/icons/icon2.png"
                    alt="Icon"
                    style={{ width: "100px" }}
                  />
                  <h3 className="mb-3">Air Freight</h3>
                  <p className="mb-4">
                  Our specialization in air freight solutions, coupled with our team of dedicated account managers and round-the-clock service assurance,...
                  </p>
                  <Link className="btn" to="/Air">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-3.jpg" alt="" />
                <div className="service-text p-5">
                  <img className="mb-4" src="img/icons/icon3.png" alt="Icon" />
                  <h3 className="mb-3">Ground Transportation</h3>
                  <p className="mb-4">
                  Our land transport services are designed to meet the specific requirements of our customers, ensuring precise alignment with their needs....
                  </p>
                  <Link className="btn" to="/Ground">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-4.jpg" alt="" />
                <div className="service-text p-5">
                  <img className="mb-4" src="img/icons/icon4.png" alt="Icon" />
                  <h3 className="mb-3">Warehousing & Distribution</h3>
                  <p className="mb-4">
                  Our company offers comprehensive warehousing network solutions, supported by well-trained manpower and excellent managerial services...
                  </p>
                  <Link className="btn" to="/Warehousing">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-5.jpg" alt="" />
                <div className="service-text p-5">
                  <img className="mb-4" src="img/icons/icon5.png" alt="Icon" />
                  <h3 className="mb-3">Projects & Break Bulk</h3>
                  <p className="mb-4">
                  Our expertise lies in handling project cargo that requires special care, attention, and expertise. We serve a wide range of industries, ..
                  </p>
                  <Link className="btn" to="/Projects">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-6.jpg" alt="" />
                <div className="service-text p-5">
                  <img className="mb-4" src="img/icons/icon6.png" alt="Icon" />
                  <h3 className="mb-3">Hazardous Cargo</h3>
                  <p className="mb-4">
                  Verve International specializes in providing comprehensive logistics solutions for dangerous and hazardous cargo. Our business is built...
                  </p>
                  <Link className="btn" to="/Hazardous">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
      
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src="img/about-1.jpg" alt="" />
                <img className="img-fluid" src="img/about-2.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h4 className="section-title">About Us</h4>
              <h1 className="display-5 mb-4">
                Welcome to {companyname}
              </h1>
              <p>
                {companyname}, your comprehensive
                solution for all your logistics requirements. As a leading
                provider, we offer a wide range of services that encompass
                freight forwarding, road transport, rail movement, coastal
                movement, warehousing, and custom clearance.
                <br />
                <br />
                At {companyname}, we recognize the
                complexities and challenges associated with logistics in any
                business operation. That's why we take pride in delivering
                customized logistics solutions tailored to your specific needs.
                With a team of experienced professionals, we possess the
                expertise and knowledge to handle all your logistics
                requirements, be it domestic or international transportation.
                <br />
                <br />
               
              </p>

            
              <Link className="btn btn-primary py-3 px-5" to="/About">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
     
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h4 className="section-title">Industry Solutions We Provide</h4>
           
          </div>
          <div className="row g-0 team-items">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/industry1.jpeg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Aerospace" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Aerospace</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/industry2.jpg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Automotive" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Automotive</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/industry3.jpeg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Consumer" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Consumer Goods</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/project-1.jpg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Energy" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Energy</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/industry5.jpg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Life" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Life Science</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/industry6.jpg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Mining" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Mining</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}
      

      <Footer />
    </>
  );
}
