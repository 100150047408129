import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from "./pages/Contact";
import Sea from "./pages/Sea";
import Warehousing from "./pages/Warehousing";
import Air from "./pages/Air";
import Ground from "./pages/Ground";
import Projects from "./pages/Projects";
import Customs from "./pages/Customs";

import Careers from "./pages/Careers";
import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Hazardous from "./pages/Hazardous";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Aerospace from "./pages/Aerospace";
import Automotive from "./pages/Automotive";
import Consumer from "./pages/Consumer";
import Energy from "./pages/Energy";
import Life from "./pages/Life";
import Mining from "./pages/Mining";
import Industries from "./pages/Industries"




import Signin from "./admin/Signin";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
        <Route path="/Industries" element={<Industries />} />
        <Route path="/Aerospace" element={<Aerospace />} />
          <Route path="/Automotive" element={<Automotive />} />
          <Route path="/Consumer" element={<Consumer />} />
          <Route path="/Energy" element={<Energy />} />
          <Route path="/Life" element={<Life />} />
          <Route path="/Mining" element={<Mining />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Sea" element={<Sea/>} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Ground" element={<Ground />} />
          <Route path="/Customs" element={<Customs />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Hazardous" element={<Hazardous />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />

        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));