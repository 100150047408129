import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Industries() {
  return (
    <>
      <Header />

     {/* Page Header Start */}
     <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-1 text-white animated slideInDown">
          Industries
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Industries
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
       {/* Team Start */}
       <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h4 className="section-title">Industry Solutions We Provide</h4>
           
          </div>
          <div className="row g-0 team-items">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/industry1.jpeg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Aerospace" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Aerospace</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/industry2.jpg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Automotive" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Automotive</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/industry3.jpeg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Consumer" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Consumer Goods</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/project-1.jpg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Energy" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Energy</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/industry5.jpg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Life" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Life Science</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative">
                <div className="position-relative">
                  <img className="img-fluid" src="img/industry6.jpg" style={{height:'240px'}} alt="" />
                  <div className="team-social text-center">
                  <Link to="/Mining" className="btn btn-primary py-3 px-5 mt-3">
                        Read More
                      </Link>
                  </div>
                </div>
                <div className="bg-light text-center p-4">
                  <h3 className="mt-2">Mining</h3>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
}
