import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Life() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-1 text-white animated slideInDown">
            Life Science
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Life Science
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="">
                <img className="img-fluid" src="img/industry3.jpeg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h4 className="section-title"> Life Science Industry</h4>
              <p>
                In the healthcare and life sciences industry, we understand the
                importance of precise and secure delivery along with critical
                communication. Our logistics services specifically cater to the
                unique requirements of this industry, offering a comprehensive
                range of solutions:
                <br />
                <br />
                1. Turn-Key Project Management: We provide end-to-end project
                management services, ensuring seamless coordination and
                execution of logistics operations for healthcare and life
                sciences projects.
                <br />
                2. Wheels Up Customs Clearance: Our dedicated team handles all
                customs clearance processes efficiently, ensuring smooth and
                expedited customs procedures for time-sensitive shipments.
                <br />
                3. Supplier Management: We effectively manage suppliers within
                the healthcare and life sciences supply chain, ensuring reliable
                and timely delivery of goods and materials.
                <br />
              </p>
            </div>
          </div>
          <p>
            <br />
            4. Temperature Control Shipments/Time-Sensitive Shipments: We
            specialize in handling temperature-sensitive shipments, maintaining
            strict temperature control throughout the transportation process to
            safeguard the integrity of healthcare and life sciences products. We
            also prioritize the timely delivery of time-sensitive shipments.
            <br />
            5. Chain of Custody Secure Supply Chain Management: Our secure
            supply chain management solutions ensure the chain of custody is
            maintained, ensuring the safety and integrity of healthcare and life
            sciences products throughout the logistics process.
            <br />
            6. Door-to-Door International and Domestic Expediting: We offer
            efficient door-to-door expediting services, both internationally and
            domestically, ensuring the smooth flow of healthcare and life
            sciences shipments from origin to destination.
            <br />
            7. FDA, USDA, and Global Customs Compliance Consulting: Our team
            provides expert advice and guidance on FDA, USDA, and global customs
            compliance regulations, ensuring full compliance and seamless
            movement of healthcare and life sciences goods across borders.
            <br />
            8. Custom Packing and Crating for Full Range of Temperature
            Requirements: We provide custom packing and crating solutions
            tailored to the specific temperature requirements of healthcare and
            life sciences products, ensuring their protection during
            transportation.
            <br />
            9. Transport Network Optimization: We optimize transport networks to
            enhance efficiency, reduce costs, and improve overall logistics
            operations within the healthcare and life sciences industry.
            <br />
            With our dedicated focus on critical communications, advanced
            visibility, and a comprehensive suite of life sciences logistics
            services, we exceed the demanding expectations of the healthcare and
            life sciences industry.
          </p>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
