import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Customs() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div
        className="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Customs Clearance
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Customs Clearance
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container text-center">
            <h2>Customs Clearance</h2>
          </div>
          <div className="wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="position-relative overflow-hidden h-100"
              style={{ minHeight: "600px" }}
            >
              <img
                className="position-absolute w-100 h-100 pt-5 pe-5"
                src="img/customs.webp"
                alt=""
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p>
              {" "}
              <br />
              <br />
              Managing customs clearance requires expert understanding of the
              subject, as customs laws and regulations change from time to time
              based on business requirements. We have a team of well-trained and
              qualified people who handle a wide range of goods and cargo
              including heavy and heavy cargo as well as dangerous goods by sea
              and air. We offer an unparalleled personalized shopping service.
              We strive to enhance our customer satisfaction by providing the
              right solution at the right time. We pay close attention to all
              the details related to the domestic and international clearance
              process and ensure that its customs department is constantly
              updated on new laws and regulations as well as the suspension of
              shipments.
              <br />
              <br />
              Our comprehensive infrastructure makes C&F operations efficient.
              Our goal is to provide quality, professional and fast service
              specific to your needs.
              <br />
              <br />
              Combining professional skills, decades of experience and
              determination, we strive to achieve complete customer satisfaction
              by providing quality customs clearance services.{" "}
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
