import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Automotive() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-1 text-white animated slideInDown">
            Automotive
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Automotive
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="">
                <img className="img-fluid" src="img/industry2.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h4 className="section-title">Automotive Industry</h4>
              <p>
                Our extensive global logistics network enables us to provide
                automotive logistics expertise and support, regardless of your
                location. Whether you operate in the United States, Canada, or
                anywhere else worldwide, our services are readily available to
                you.
                <br />
                In addition to our logistics operations, we offer international
                trade advisory and customs brokerage services to assist you in
                making strategic decisions for your automotive logistics and
                supply chain. Our team of experts provides valuable insights and
                expertise in international trade, helping you gain a competitive
                advantage in the automotive industry.
                <br />
              </p>
            </div>
          </div>
          <p>
            <br />
            As the usage of Electric Vehicles (EVs) continues to rise, we are
            well-equipped to support the industry's evolving needs. Our services
            range from the storage and delivery of EV charging stations to
            battery logistics. If you require assistance in optimizing your EV
            supply chain, please don't hesitate to contact us for a personalized
            review of your requirements. Our goal is to work in partnership with
            our clients, helping them develop a competitive edge in their supply
            chain operations.
            <br />
            With our agile and flexible automotive logistics solutions, our team
            of experts is ready to assist you. Feel free to reach out to us if
            you require any support or have specific supply chain needs.
            <br />
          </p>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
