import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-1 text-white animated slideInDown">
          Contact Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Contact Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
   {/* Contact Start */}
   <div className="container-xxl py-5">
          <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '600px'}}>
              <h4 className="section-title">Contact Us</h4>
              <h1 className="display-5 mb-4">If You Have Any Query, Please Feel the form to contact us..</h1>
            </div>
            <div className="row g-5">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="d-flex flex-column justify-content-between h-100">
                  <div className="bg-light d-flex align-items-center w-100 p-4 mb-4">
                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-dark" style={{width: '55px', height: '55px'}}>
                      <i className="fa fa-map-marker-alt text-primary" />
                    </div>
                    <div className="ms-4">
                      <p className="mb-2">Address</p>
                      <h3 className="mb-0">{companyaddress}</h3>
                    </div>
                  </div>
                  <div className="bg-light d-flex align-items-center w-100 p-4 mb-4">
                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-dark" style={{width: '55px', height: '55px'}}>
                      <i className="fa fa-phone-alt text-primary" />
                    </div>
                    <div className="ms-4">
                      <p className="mb-2">Call Us Now</p>
                      <h3 className="mb-0">{companynumber}</h3>
                    </div>
                  </div>
                  <div className="bg-light d-flex align-items-center w-100 p-4">
                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-dark" style={{width: '55px', height: '55px'}}>
                      <i className="fa fa-envelope-open text-primary" />
                    </div>
                    <div className="ms-4">
                      <p className="mb-2">Mail Us Now</p>
                      <h3 className="mb-0">{companyemail}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <form
                    action="/php/thankyou-contact.php "
                    method="post"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        className
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                      <span />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                      <input
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                      </div>
                    </div>
                    <div className="col-12">
                      <button name="submit" className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              <h1>{result}</h1>

              </div>
            </div>
          </div>
        </div>
        {/* Contact End */}
        {/* Google Map Start */}
        <div className="container-xxl pt-5 px-0 wow fadeIn" data-wow-delay="0.1s">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.2354709347555!2d73.02821227224611!3d19.00934291470414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3a9bec8571d%3A0xe02b1a52eb7352f2!2sThapar%20Complex%2C%20Sector%2015%2C%20CBD%20Belapur%2C%20Navi%20Mumbai%2C%20Maharashtra%20400614!5e0!3m2!1sen!2sin!4v1689144719408!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>            

          </div>
        {/* Google Map End */}


      <Footer />
    </>
  );
}
