import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
      {/* Footer Start */}
      <div className="container-fluid bg-dark text-body footer mt-5 pt-5 px-0 wow fadeIn" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6">
              <Link to="/Home" className="navbar-brand ms-4 ms-lg-0">
            <img className="me-3" src="img/logo.png"  alt="Icon" style={{height:'120px'}} />
          </Link>
                <div className="d-flex pt-2">
                  <p> At {companyname}, we recognize the complexities and challenges
                associated with logistics in any business operation. That's why
                we take pride in delivering customized logistics solutions
                tailored to your specific needs.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <h3 className="text-light mb-4">Services</h3>
                <Link to="/Sea" className="btn btn-link">Sea Freight</Link>
                  <Link to="/Air" className="btn btn-link">Air Freight</Link>
                  <Link to="/Ground" className="btn btn-link">Ground Transportation</Link>
                  <Link to="/Warehousing" className="btn btn-link">Warehousing & Distribution</Link>
                  <Link to="/Projects" className="btn btn-link">Projects & Break Bulk</Link>
                  <Link to="/Hazardous" className="btn btn-link">Hazardous Cargo</Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <h3 className="text-light mb-4">Quick Links</h3>
                <Link to="/Home" className="btn btn-link">Home </Link>
                <Link to="/About" className="btn btn-link">About Company </Link>
                <Link to="/Services" className="btn btn-link">Services </Link>
                <Link to="/Industries" className="btn btn-link">Industries </Link>
                <Link to="/Contact" className="btn btn-link">Contact </Link>
                <Link to="/Getquote" className="btn btn-link">Getquote </Link>


              </div>
              <div className="col-lg-3 col-md-6">
                <h3 className="text-light mb-4">Address</h3>
                <p className="mb-2"><i className="fa fa-map-marker-alt text-primary me-3" />{companyaddress}</p>
                <p className="mb-2"><i className="fa fa-phone-alt text-primary me-3" />{companynumber}</p>
                <p className="mb-2"><i className="fa fa-envelope text-primary me-3" />{companyemail}</p>
                
              </div>
            </div>
          </div>
          <div className="container-fluid copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                  © <Link to="/Home">{companyname}</Link>, All Right Reserved.
                </div>
                <div className="col-md-6 text-center text-md-end">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer End */}
        {/* Back to Top */}
        <Link to="/Getquote" className="btn btn-lg btn-primary  back-to-top">Request Quote</Link>

      <Outlet />
    </>
  );
};

export default Footer;
